import * as React from 'react';

export const createIconFromString = (svg: string) => {
  return React.createElement('span', {
    dangerouslySetInnerHTML: {
      __html: svg || '',
    },
  });
};

export const addPrefixToId = (svg: string, prefix: string): string => {
  if (!svg) {
    return svg;
  }

  return svg.replace(
    /(id="|url\(#|href="#)([^"]+)(?=[")])/g,
    (match, prefixOrUrlOrHref, id) => {
      return `${prefixOrUrlOrHref}${prefix + id}`;
    },
  );
};
